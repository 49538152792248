<template>
    <b-row>
        <b-col cols="12" v-if="can('tab-generate/pengajuan-npp')">
          <b-card title="Pengajuan NPP Generated">
            <b-tabs>
              <b-tab lazy title="Data" @click="goToData" v-if="can('tab-data/pengajuan-npp')">
              </b-tab>
              <b-tab active lazy title="Generated">
              </b-tab>
            </b-tabs>
            <b-row class="mb-1 justify-content-between align-items-end">
              <b-col md="4" lg="3" class="mb-1 mb-md-0">
                <label>Status</label>
                <b-form-select
                  v-model="filterStatus"
                  :options="filterStatusOptions"
                ></b-form-select>
              </b-col>
              <b-col md="4" xs="12">
                <b-input-group size="8">
                  <b-form-input
                    v-model="search"
                    type="search"
                    :placeholder="'Cari disini...'"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!search" @click="search = ''">
                      Hapus
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <b-table
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            primary-key="email"
            :per-page="perPage"
            :current-page="currentPage"
            :items="getItems"
            :fields="fields"
            ref="tableFe"
            >
            <template #empty>
                <div class="text-center my-2">Data tidak tersedia</div>
            </template>
            <template #emptyfiltered>
                <div class="text-center my-2">Data tidak tersedia</div>
            </template>
            
            <template #cell(no)="data">
                {{ (currentPage - 1) * perPage + (data.index + 1) }}
              </template>
              <template #cell(date)="data">
                {{ data.item.date | moment('DD/MM/YY') }}
              </template>
              <template #cell(aksi)="data">
                <div style="min-width: 80px">
                  <b-button
                    v-if="can('show/pengajuan-npp')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-popover.hover.bottom="'Detail'"
                    variant="info"
                    size="sm"
                    class="btn-icon float-sm-left mr-1"
                    @click="gotoDetailPage(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="can('export/pengajuan-npp')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-popover.hover.bottom="'Export'"
                    variant="secondary"
                    size="sm"
                    class="btn-icon float-sm-left"
                    @click="exportData(data.item.id, data.item.proposal_number)"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>

            <span v-if="totalRows == 1" style="margin: 1rem"></span>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
                label="Per Page"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
            >
                <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    @change="changePerPage()"
                    size="sm"
                    inline
                    :options="pageOptions"
                />
            </b-form-group>

            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
            </b-card-body>

          </b-card>
        </b-col>
        <b-col v-else>
          <b-card>
            <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
              <div class="w-100 text-center">
                  <h2 class="mb-1">
                      Tidak Memiliki Akses
                  </h2>
                  <p class="mb-2">
                      Anda tidak memiliki akses pada halaman ini
                  </p>
              </div>
            </div>
          </b-card>
        </b-col>
    </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
</style>

<script>
import { BRow, BCol, BTable, BCard, BTabs, BTab, BButton, BButtonGroup, BInputGroup, BFormRadioGroup, BForm, BFormInput, BFormTextarea, BInputGroupAppend, BCardBody, BFormGroup, BFormSelect, BPagination, VBPopover, BFormCheckbox } from "bootstrap-vue";
import TableFe from "@/views/table/bs-table/TableFE";
import axios from "@axios";
import Ripple from "vue-ripple-directive";

export default {
  name: "SppdList",
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BTabs,
    BTab,
    TableFe,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormRadioGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BPagination,
    VBPopover,
    BFormCheckbox
  },
  directives: {
    Ripple,
    "b-popover": VBPopover
  },
  data() {
    return {
      config: {
        api: '/npps',
      },
      errors: '',
      model: {
        note: '',
        status: ''
      },
      show1: false,
      show2: false,
      filterStatus: 0,
      filterStatusOptions: [
        {
          value: 0,
          text: 'Waiting Approval',
        },
        {
          value: 1,
          text: 'Approved',
        },
      ],
      search: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 10,
      sortBy: 'id',
      pageOptions: [1, 10, 25, 50, 100],
      fields: [
        'no',
        { key: 'proposal_number', label: 'No Proposal' },
        { key: 'regional_name', label: 'Kantor' },
        { key: 'code_regional', label: 'No Proposal Reg', thStyle: 'width:300px' },
        { key: 'total_employees', label: 'Jumlah Pegawai' },
        { key: 'date', label: 'Tanggal Generate' },
        { key: 'aksi', label: 'Aksi', thStyle: 'width:80px'}
      ],
      items: [],
    };
  },
  watch: {
    filterStatus() {
      this.$refs.tableFe.refresh()
    },
    search: function () {
      this.$refs.tableFe.refresh()
    },
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    goToData() {
      let _ = this;
      _.$router.push("/pengajuan-npp");
    },
    getItems(ctx, callback) {
      const _ = this

      let endpoint = `${_.config.api}?per_page=${_.perPage}&page=${_.currentPage}&status=${this.filterStatus}`
      if (_.search) {
        endpoint += `&key=${_.search}`
      }
      axios
        .get(endpoint)
        .then(resp => {
          const consume = resp.data.data
          _.perPage = consume.per_page
          _.currentPage = consume.current_page
          _.totalRows = consume.total
          callback(consume.data || [])
        })
        .catch(err => {
          console.log(err)
        })
      return null
    },
    handleSearch() {
      this.$refs.tableFe.refresh()
    },
    changePerPage() {
      const _ = this
      _.$refs.tableFe.refresh()
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
    gotoDetailPage(id) {
      this.$router.push({
        name: 'pengajuan-npp-detail',
        params: { id },
      })
    },
    exportData(id, proposalNumber) {
      axios({
        url: `${this.config.api}/${id}/export`,
        method: 'GET',
        responseType: 'blob',
      }).then(res => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${proposalNumber}-${id}.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
  },
};
</script>

<style>
.b-popover{
  font-size: 10px;
}
</style>
<template>
  <b-card :title="tableTitle">
    <div class="mb-2">
      <!-- filter -->
      <b-col md="6">
        <slot name="filter"></slot>
      </b-col>
      <div class="d-flex justify-content-between flex-wrap">
        
        <div>
          <b-button
            v-if="back_indicator == 'true'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-left"
            variant="outline-secondary"
            @click="moveBack"
          >
            Kembali
          </b-button>
          <b-button
            v-if="creates == 'true'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="ml-0"
            @click="gotoCreatePage()"
          >
            Tambah
          </b-button>
            <b-button
            v-if="createsCustom == 'true'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="ml-0"
            @click="gotoCreatePageCustom()"
          >
            Tambah
          </b-button>
          <!-- <b-button
            v-if="back_indicator == 'true'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            @click="moveBack"
          >
            Kembali
          </b-button> -->
        </div>
        <!-- filter -->
        <b-form-group
          v-if="search == 'true'"
          label-cols-sm="2"
          label-align-sm="right"
          label-size=""
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="8">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="'Cari disini...'"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Hapus
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      primary-key="email"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :sort-direction="sortDirection"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      ref="tableFe"
    >
      <template #empty>
        <div class="text-center my-2">Data tidak tersedia</div>
      </template>
      <template #emptyfiltered>
        <div class="text-center my-2">Data tidak tersedia</div>
      </template>
      <template #cell(no)="data">
        {{ (currentPage - 1) * perPage + (data.index + 1) }}
      </template>

      <template #cell(status_active)="data">
        {{
          data.item.account && data.item.account.verified == true
            ? "Verified"
            : "Not Verified"
        }}
      </template>

       <template #cell(use_district_income)="data">
         <span class="text-capitalize">{{data.item.use_district_income}}</span>
      </template>

      <template #cell(description)="data">
         <span v-html="data.item.description"></span>
      </template>

      <template #cell(created_at)="data">
         <span class="text-capitalize">{{data.item.created_at | moment("DD/M/YYYY")}}</span>
      </template>

      <template #cell(date)="data">
         <span class="text-capitalize">{{data.item.date | moment("DD/MM/YYYY")}}</span>
      </template>

      <template #cell(total)="data">
         <span class="text-capitalize">{{data.item.total | currency}}</span>
      </template>

      <template #cell(daily_trip_money)="data">
         <span class="text-capitalize">{{data.item.daily_trip_money | currency}}</span>
      </template>

      <template #cell(pph21)="data">
         <span class="text-capitalize">{{data.item.pph21 | currency}}</span>
      </template>

      <template #cell(status)="data">
         <span class="text-capitalize" v-if="data.item.status == 1">{{'Aktif'}}</span>
         <span class="text-capitalize" v-else>{{'Tidak Aktif'}}</span>
      </template>

      <template #cell(type)="data">
         <span class="text-capitalize">{{data.item.type.name}}</span>
      </template>

      <template #cell(type_non_employee)="data">
         <span class="text-capitalize">{{data.item.type_non_employee.name}}</span>
      </template>

      <!-- A virtual column -->
      <template #cell(Aksi)="data">
        <div style="min-width: 270px">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            v-b-popover.hover.bottom="'Detail'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="shows == 'true'"
            @click="gotoDetailPage(data.item.id)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <div>
            <b-form-checkbox
              v-if="is_priority == 'true'"
              :checked="data.item.broadcastStatus == true"
              v-b-popover.hover.bottom="'Priority'"
              class="custom-control-primary float-sm-left"
              name="check-button"
              @change="confirmShowHide(data.item.id, data.item.broadcastStatus)"
              switch
              style="margin-top:5px;"
            >
              <span class="switch-icon-left">
                <feather-icon icon="EyeIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="EyeOffIcon" />
              </span>
            </b-form-checkbox>
          </div>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            v-b-popover.hover.bottom="'Ubah'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="updates == 'true'"
            @click="gotoEditPage(data.item.id)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

           <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            v-b-popover.hover.bottom="'Ubah'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="updatesCustom == 'true'"
            @click="gotoEditCustom(data.item.id)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            v-b-popover.hover.bottom="'Permission Role'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="permissions == 'true'"
            @click="gotoPermissionPage(data.item.id)"
          >
            <feather-icon icon="LockIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            v-b-popover.hover.bottom="'Position Role'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="permissions == 'true'"
            @click="gotoPositionPage(data.item.id)"
          >
            <feather-icon icon="ListIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            v-b-popover.hover.bottom="'Hapus'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="deletes == 'true' && userId != data.item.id"
            @click="confirmText(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            v-b-popover.hover.bottom="'Hapus'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="deletescustome == 'true'"
            @click="confirmTextCustome(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-popover.hover.bottom="'Setting Legend'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="legend == 'true'"
            @click="gotoLegendPage(data.item.id)"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            v-b-popover.hover.bottom="'Data Event'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="data_event == 'true'"
            @click="gotoEventPage(data.item.id)"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>

           <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            v-b-popover.hover.bottom="'Data Highlight'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="data_highlight == 'true'"
            @click="gotoHighlightPage(data.item.id)"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>

        </div>
      </template>
    </b-table>
    <!-- <b-table :fields="fields" :items="items" show-empty>
      
    </b-table> -->
    <span v-if="totalRows == 1" style="margin: 1rem"></span>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="onTableCurrentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>
<style>
.b-popover{
  font-size: 10px;
}
</style>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCol,
  BModal,
  VBModal,
  BForm,
  BFormRadioGroup,
  BFormTextarea,
  VBPopover,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";

import Ripple from "vue-ripple-directive";
import { EventBus } from "@/eventBus.js";
import BCardCode from "@core/components/b-card-code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: [
    "tableTitle",
    "apiUrl",
    "perPage",
    "currentPage",
    "items",
    "fields",
    "totalRows",
    "apiBase",
    "creates",
    "updates",
    "createsCustom",
    "updatesCustom",
    "deletes",
    "shows",
    "permissions",
    "search",
    "is_priority",
    "data_event",
    "data_highlight",
    "legend",
    "back_indicator",
    "deletescustome",
    "apiDelete",
    "userId"
  ],
  components: {
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    vSelect,
    BModal,
    BCardCode,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-popover": VBPopover,
  },
  data() {
    return {
      errors: {},
      onTablePerPage: this.$props.perPage,
      onTableCurrentPage: this.$props.currentPage,
      pageOptions: [1, 10, 25, 50, 100],
      filter: null,
      sortDirection: "asc",
      sortBy: "",
      sortDesc: false,
      filterOn: [],
      urlBase : this.$props.apiBase + "s",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      model: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options   list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    let _ = this;
    // Set the initial number of items
    EventBus.$on("changeValue", () => {
      _.$refs.tableFe.refresh();
    });
  //  console.log(this.userId, "ini adalah");
  //  console.log(this.$props.apiUrl, "tetst")
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      console.log(filteredItems);
    },
    changePerPage() {
      this.$emit("changePerPage", this.onTablePerPage);
      console.log(this.onTablePerPage, "per-page")
    },
    moveBack(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl );
    },
    
    gotoEditPage(id) {
      let _ = this;
      window.location = "/" + _.$props.apiUrl + "/" + id + "/edit";
    },
    gotoDetailPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "/" + id + "/detail");
    },
    gotoPermissionPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "/" + id + "/permission");
    },
    gotoPositionPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "/" + id + "/position");
    },
    gotoCreatePage() {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "/add");
    },
    gotoLegendPage(id) {
      let _ = this;
      
      _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/legend");
    },

    gotoEditCustom(id) {
      let _ = this;
      _.$router.push(_.$props.apiUrl + "/" + id + "/edit");
    },
    gotoCreatePageCustom() {
      let _ = this;
      _.$router.push(_.$props.apiUrl + "/add");
    },

    gotoEventPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/event");
    },
    gotoHighlightPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/highlight");
    },
    confirmText(id) {
      let _ = this
      console.log(_.$props.apiUrl);
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {

        if (result.value) {
          axios
            .delete(_.$props.apiBase + "/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Dihapus",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableFe.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak bisa di hapus",
                text: _.$props.apiUrl == 'role' ? 'Role tidak dapat dihapus karena sedang dipakai oleh user' : err.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    confirmTextCustome(id) {
      let _ = this
      console.log(_.$props.apiDelete);
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete(_.$props.apiBase + "/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Dihapus",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableFe.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak bisa di hapus",
                text: err.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
  },
  watch: {
    onTableCurrentPage: function (newValue) {
      let _ = this;
      _.$emit("changeCurrentPage", newValue);
    },
    items: function (newValue) {
      console.log(newValue);
      _.totalRows = _.items.length;
    },
  },
};
</script>
